import axios from "axios"
import * as rax from "retry-axios"

// create instance
const api = axios.create({
	baseURL: process.env.API_URL || "https://test.store.tltgames.net:8080/",
	withCredentials: false,
	raxConfig: {
		retry: 3,
		retryDelay: 3000,
		noResponseRetries: 3,
		backoffType: "static",
	},
	headers: {
		common: {
			"Content-Type": "application/json",
			Accept: "application/json",
			// "X-Requested-With": "XMLHttpRequest",
		},
	},
})

const interceptorId = rax.attach(api)

api.interceptors.request.use(
	(x) => {
		if (process.env.NODE_ENV === "development") {
			console.log(`${x.method.toUpperCase()} | ${x.url}`, x.params, x.data)
		}

		return x
	},
	async (error) => {
		return Promise.reject(error)
	}
)

api.interceptors.response.use(
	(x) => {
		if (process.env.NODE_ENV === "development") {
			console.log(`${x.status} | ${x.config.url}`, x.data)
		}

		return x
	},
	async (error) => {
		return Promise.reject(error)
	}
)

const initAxios = (app) => {
	app.config.globalProperties.$api = api
}

const setDefaultAxiosParam = (param, value) => {
	api.defaults.params = api.defaults.params || {}
	api.defaults.params[param] = value
}

const setDefaultAxiosHeader = (param, value) => {
	api.defaults.headers = api.defaults.headers || { common: {} }
	api.defaults.headers.common[param] = value
}

export { initAxios, setDefaultAxiosParam, setDefaultAxiosHeader, api as $api }
